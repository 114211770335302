import React from 'react';
import Header from '../components/Header';

function Votes(props) {
    return (
        <div>
            <Header />
            <h1>Votes</h1>
        </div>
    );
}

export default Votes;