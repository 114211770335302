import React from 'react';
import { Link } from 'react-router-dom';
import classes from '../styles/home.module.scss';
import Logo from '../components/Logo';

function Home() {
    return (
        <div className={classes.home}>
            <div className={classes.home__top}>
                <h1 className={classes.home__top__title}>ORU - Dibistan</h1>
                <p className={classes.home__top__text}>
                    L'Organisation des Régions Unies est une organisation liée directement au Dibistan. Cette organisation fut créée peu de temps
                    après la création du pays et avait pour but d'aider les différentes régions du pays à établir leur
                    limites sans déborder sur les autres, par le biais de représentants.
                    <br /><br />
                    Aujourd'hui l’Organisation des Régions Unies n’est plus un organe politique,  son but est dès à présent
                    de coordonner les territoires, tant dans le dialogue lors de conflits que dans la transmission d’informations.
                    L’ORU est pour les citoyens, par le biais des territoires. L’ORU va donc aider à transmettre les informations
                    facilement grâce aux représentants en ce qui concerne la politique et grâce à des salons pour tous les événements.
                    <br />
                    Merci à tous de continuer à faire vivre ce projet. Nous vous invitons à le rejoindre (via Discord), que vous soyez représentant d'un territoire ou non.
                    <br /><br />
                    Bonne journée !
                </p>
                <div className={classes.home__top__wave}><svg className={classes.home__top__wave__svg} data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none"><path className={classes.home__top__wave__svg__path} d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z"></path></svg></div>
            </div>
            <Logo className={classes.home__logo} />

            <div className={classes.home__bottom}>
                <div className={classes.home__bottom__container}>
                    <a className={classes.home__bottom__container__button} href="https://discord.gg/bznYx327WU">Serveur Discord</a>
                    <a className={classes.home__bottom__container__button} href="https://docs.google.com/document/d/e/2PACX-1vQ3csFWSA7W_UVxkhrP5PNHv6BvYjBwAQYS-RPe4BzaCPN-ZaT8If9tNI9tST69oGW7vLEuXvQ0TDie/pub?embedded=true" target="_blank" rel="noreferrer">DOIFO</a>
                    <Link className={classes.home__bottom__container__button} to="/">Données</Link>
                    <Link className={classes.home__bottom__container__button} to="/">Votes</Link>
                </div>
            </div>
        </div>
    );
}

export default Home;