import React from 'react';
import { Link } from 'react-router-dom';
import classes from '../styles/header.module.scss';
import { BsDiscord } from 'react-icons/bs';
import { MdAccountCircle } from 'react-icons/md';
import Logo from './Logo';


export default function Header(props) {
    return (
        <header className={classes.header}>
            <Logo className={classes.header__logo} />
            <h1 className={classes.header__title}>Organisation des Régions Unies - Dirtybiologistan</h1>
            <MdAccountCircle className={classes.header__icon}/>
        </header>
    );
}