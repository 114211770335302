import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Donnees from './pages/Donnees';
import Home from './pages/Home';
import Votes from './pages/Votes';

function App() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={ <Home /> } />
                <Route path="/donnees" element={ <Donnees /> } />
                <Route path="/votes" element={ <Votes /> } />
            </Routes>
        </BrowserRouter>
    );
}

export default App;
